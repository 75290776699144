


























import { Component, Vue } from 'vue-property-decorator'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import HighlightText from '@/components/HighlightText.vue'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'

@Component({
  components: {
    ContentArea,
    Title,
    PageLayout,
    HighlightText
  }
})
export default class Faq extends Vue {
  private searchText = ''
  private faqData = []
  private filteredData = []
  private activeIndex = -1

  private created (): void {
    this.$store.dispatch('faq/getFaqData').then((res:any) => {
      this.faqData = res
    })
  }

  private clearSearch (): void {
    this.searchText = ''
    this.filteredData = []
  }

  private search (): void {
    this.activeIndex = -1
    const data = [...this.faqData]
    const a:any = []
    data.forEach((el:any) => {
      el.question = el.question.toLowerCase()
      a.push(el)
    })
    this.filteredData = a.filter((e:any) => e.question.includes(this.searchText.toLowerCase()))
    this.filteredData.forEach((item:any) => {
      item.question = item.question.charAt(0).toUpperCase() + item.question.slice(1)
    })
  }

  private openAnswer (index: number): void {
    console.log(index)
  }
}
